import "./styles/general.css";
import "./styles/font-size-class_link.css";
import "./styles/enroll-form.css";

import { HomePage } from './pages/HomePage';
import { CoursesPage } from './pages/CoursesPage';
import { AboutusPage } from "./pages/AboutusPage";
import { FaqsPage } from "./pages/FaqsPage";
import { SyllabusPage } from "./pages/SyllabusPage";
import { Route, Routes } from "react-router-dom";
import { ContactSocials, Navigation } from "./components/Header";
import { Footer } from "./components/Footer";
import { SuccessStoriesPage } from "./pages/SuccessStoriesPage";
import { FloatingWhatsapp } from "./components/FloatingWhatsapp";


function App() {

  return (
    <>
      <FloatingWhatsapp />
      <ContactSocials />
      <Navigation />
      <div style={{
        height: "40px",
        width: "100vw",
        minHeight: "40px"
      }}></div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/coursePage" element={<CoursesPage />} />
        <Route path="/aboutusPage" element={<AboutusPage />} />
        <Route path="/faqsPage" element={<FaqsPage />} />
        <Route path="/syllabus/:id" element={<SyllabusPage />} />
        <Route path="/successStories" element={<SuccessStoriesPage />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
