import testimonialM from "../icons/testimonial_m.png";
import testimonialF from "../icons/testimonial_f.png";
import useWindowDimensions from "../utils/window.js";
import { Link } from "react-router-dom";
import { ButtonOrange } from "./AssetButton";
import TrophyImage from "../images/success-stories-trophy.png";
import TrophyImageFlipped from "../images/success-stories-trophy-flip.png";



import { useEffect, useState } from "react"
import axios from "axios";


export default function Testimonials() {
    const [chats, setChats] = useState([]);

    useEffect(() => {
        axios
            .get("https://admin.insideit.in/api/testimonial/read.php")
            .then((res) => setChats(res.data.slice(0, 3)))
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }, [])

    return (
        <>
            <div className="container-fluid bg--color-grey px-0 px-md-3 pt-3 pt-md-5">
                <div className="row justify-content-center align-items-center mx-0">
                    <div className="col col-2 col-md-auto">
                        <img className="img-fluid" style={{ width: "100px" }} src={TrophyImageFlipped} alt=""></img>
                    </div>
                    <div className="col col-auto">
                        <div className="text-center fs-24 fs-md-40 oswald-heading text-primary">
                            SUCCESS STORIES
                        </div>
                    </div>
                    <div className="col col-2 col-md-auto">
                        <img className="img-fluid" style={{ width: "100px" }} src={TrophyImage} alt=""></img>
                    </div>
                </div>
                <div className="container-fluid text-center text-primary generic-text-lato pt-4 px-5">Discover what our students are saying about us and Inside IT</div>
                <GenerateTestimonials chats={chats} />
                <div className="container-fluid text-center pb-3">
                    <Link to={"/successStories"}>
                        <ButtonOrange buttonName={"SEE MORE"} />
                    </Link>
                </div>
            </div>
        </>
    )
}

function GenerateTestimonials({ chats }) {
    const chatHistory = [];
    // eslint-disable-next-line
    const { _height, width } = useWindowDimensions();

    chats.forEach((chat) => {
        chatHistory.push(
            <div className="col">
                <ChatComponent chat={chat} />
            </div>
        )
    });

    let render = [];
    if (width <= 992) {
        render = <CarouselComponent chats={chatHistory} />
    } else {
        render = <div className="testimonial-font-family row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3 justify-content-center py-3 py-md-5">
            {chatHistory}
        </div>
    }

    return (
        <>
            {render}
        </>
    )
}

export function ChatComponent({ chat }) {
    return (
        <>
            <div className="profile d-flex flex-row align-items-center">
                <div className="p-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                    </svg>
                </div>
                <div className="p-2">
                    <img className="img-fluid" style={{ width: "50px" }} src={(chat.photo === "m") ? testimonialM : testimonialF} alt="student-profile"></img>
                </div>
                <div className="p-2">{chat.name}</div>
            </div>
            <div className="chat-window container-fluid py-2 row m-0">
                <ChatMessages chatData={chat.data} />
            </div>
        </>
    )
}

function ChatMessages({ chatData }) {
    const chatMessages = [];
    chatData.forEach((val) => {
        if (val.sender === "student") {
            chatMessages.push(
                <StudentMessage message={val.message} />
            );
        } else {
            chatMessages.push(
                <InsideItMessage message={val.message} />
            );
        }
    });


    return (<>
        {chatMessages}
    </>)
}

function StudentMessage({ message }) {
    return (
        <>
            <div className="col-11">
                <div className="row">
                    <div className="student-message mb-1 p-2 fs-12 fs-md-16 col-auto">{message}</div>
                </div>
            </div>
        </>
    )
}

function InsideItMessage({ message }) {
    return (
        <>
            <div className="col-10 offset-2">
                <div className="row justify-content-end">
                    <div className="insideit-message mb-1 p-2 fs-12 fs-md-16 col-auto">{message}</div>
                </div>
            </div>
        </>
    )
}

function CarouselComponent({ chats }) {
    return <>
        <div className="container pt-4 pb-3 pb-md-5 px-0">
            <div id="chatCarousel" className="carousel slide d-flex justify-content-around align-items-center">
                <button className="carousel-control-prev align-self-stretch" type="button" data-bs-target="#chatCarousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <div className="carousel-inner">
                    {
                        chats.map((chat, index) => {
                            if (index === 0) {
                                return (<>
                                    <div className="carousel-item active">{chat}</div>
                                </>)
                            } else {
                                return (<>
                                    <div className="carousel-item">
                                        {chat}
                                    </div>
                                </>)
                            }
                        })
                    }
                </div>
                <button className="carousel-control-next align-self-stretch" type="button" data-bs-target="#chatCarousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div >
    </>
}
