
import { HeroSection } from "../components/HeroSection";
import { ServiceVideoSection } from "../components/ServiceVideoSection";
import { ValuePropositions } from "../components/ValuePropositions";
import { SuccessStats } from "../components/SuccessStats";
import { Courses } from "../components/Course";
import Testimonials from "../components/Testimonial";
import { EnrollForm } from "../components/EnrollForm";
import { CompanyLogoSection } from "../components/CompanyLogoSection";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export function HomePage() {

    let form = useRef();
    let location = useLocation();

    useEffect(() => {
        if (location.pathname === "/" && location.hash === "#footer") {
            console.log("LocationPath: " + location.pathname);
            // console.log("Form: " + form.current);
            form.current?.scrollIntoView({ behavior: "smooth" });
        } else if (location.pathname === "/" && !location.hash) {
            window.scrollTo(0, 0);
        }
    }, [location])

    return (
        <>
            <HeroSection />
            <ServiceVideoSection />
            <ValuePropositions />
            <CompanyLogoSection />
            <Courses />
            <SuccessStats />
            <Testimonials />
            <EnrollForm formRef={form} />
        </>
    )
}