export function ButtonCream({ buttonName }) {
    return (
        <Button name={buttonName} btnStyle={"btn-primary"} />
    );
}

export function ButtonOrange({ buttonName }) {
    return (
        <Button name={buttonName} btnStyle={"btn-secondary"} />
    );
}

export function ButtonCreamDark({ buttonName }) {
    return (
        <Button name={buttonName} btnStyle={"btn-primary-dark"} />
    );
}

export function Button({ name, btnStyle }) {
    return (
        <>
            <button className={"btn fs-16 fs-md-24 button-text py-2 px-3 py-md-2 px-md-4 my-md-4 my-3 " + btnStyle}>
                {name}
            </button>
        </>
    )
}