import statImage from '../icons/stat-image.svg';
import { SectionHeadingBlack } from "./SectionHeading";


const statList = [

    {
        statName: "Students Trained",
        statImage: statImage,
        statValue: "1000+"
    },


    {
        statName: "Training Hours Delivered",
        statImage: statImage,
        statValue: "10K+"
    },

    {
        statName: "Placement",
        statImage: statImage,
        statValue: "100%"
    },

];

export function SuccessStats() {
    return (
        <>
            <div className="container-fluid bg--color-green">
                <SectionHeadingBlack headingName={"Success Stats"} />

                <div className="row row-cols-1 row-cols-md-3 g-3 py-3 py-md-5 justify-content-center">
                    <StatsGrid statList={statList} />
                </div>

            </div>
        </>
    );
}


function StatsGrid({ statList }) {

    const statCards = [];
    statList.forEach((stat) => {

        statCards.push(
            <div className="col col-lg-3">
                <div className='text-center d-flex justify-content-center flex-column'>
                    <div className='container-sm px-0 hstack gap-3 gap-md-4 justify-content-center'>
                        <StatImage statImage={stat.statImage} />
                        <div className='vr rounded-3 vr-stats'></div>
                        <StatValue statValue={stat.statValue.toUpperCase()} />
                    </div>
                    <StatName statName={stat.statName} />
                </div>
            </div>
        );
    });

    return (
        <>
            {statCards}
        </>
    )

}

function StatImage({ statImage }) {
    return (
        <img className='stat-image img-fluid' src={statImage} alt="Stat" />
    );
}

function StatValue({ statValue }) {
    return (
        <div className="oswald-heading fs-32 fs-md-40 text-secondary lh-1">
            {statValue}
        </div>
    );
}

function StatName({ statName }) {
    return (
        <div className='container-sm generic-text-lato fs-16 fs-md-20 py-1 px-0'>
            {statName}
        </div>
    )
}