import axios from "axios";
import { useState, useEffect } from "react";
import { Toast } from "./Toast";

export function EnrollForm({ formRef }) {
    const [courseList, setCourseList] = useState([{}]);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [course, setCourse] = useState('');
    const [toastDisplay, setToastDisplay] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastValid, setToastValid] = useState(false);
    // const formRef = useRef("");
    // formLinkRef.current = formRef.current

    const RaiseToast = (message, valid, time = null) => {
        //console.log("here")
        setToastMessage(message);
        setToastValid(valid);
        setToastDisplay(true);
        if (time)
            window.setTimeout(() => {
                setToastDisplay(false);
            }, time)
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        // Validate values here
        //additional data manipulation if required

        var data = new FormData();

        console.log("" + name + phone + email + course);

        if (name === "" || phone === "" || email === "" || course === "") {
            RaiseToast("All fields are mandatory", false, 5000);
            return;
        }

        data.append("FullName", name);
        data.append("PhoneNumber", phone);
        data.append("Email", email);
        data.append("CourseSelector", course);

        RaiseToast("Please Wait", true, 5000);

        axios.post("https://admin.insideit.in/api/form/create.php", data).then((res) => {
            console.log(res.data.message);
            if (res.data.message === "data added") {
                formRef.current.reset()
                RaiseToast("Form submitted successfully.", true, 5000);
            }

            // check and raise toast in then
            // can remove console log later

        }).catch((err) => {
            console.log(err);
            // remove log later
        })
    };

    useEffect(() => {
        axios
            .get("https://admin.insideit.in/api/course/read.php")
            .then((res) => {
                let list = [];
                res.data.forEach(item => {
                    list.push({
                        courseName: item.courseTitle,
                        courseId: item.courseId,
                    })
                })
                console.log(list)
                setCourseList(list)
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }, [])
    return (
        <>
            <div id="form" className="container-fluid row bg--color-green justify-content-center montserrat fs-16 px-0 mx-0 py-3 py-md-5">
                <div className="col-12 col-md-4 align-self-center px-0">
                    <div className="container-fluid text-center fs-24 fs-md-40 oswald-heading text-secondary px-0 py-3">
                        {"Enroll Now".toUpperCase()}
                    </div>
                </div>
                <div className="vr-form col-md-1 d-none d-md-block align-self-center text-center px-0">
                    <div className="vr"></div>
                </div>

                <div className="col-md-5 align-self-center px-2 px-md-0 py-3 py-md-5">
                    <form className="customer-contact-form container" action="https://admin.insideit.in/api/form/create.php" method="POST" ref={formRef}>
                        <div className="col py-1">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="FullName" name="FullName"
                                    placeholder="Name" onChange={event => setName(event.target.value)} />
                                <label for="FullName">Name</label>
                            </div>
                        </div>
                        <div className="col py-1">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="PhoneNumber"
                                    placeholder="xxxxxxxxxx" name="PhoneNumber" onChange={event => { setPhone(event.target.value) }} />
                                <label for="PhoneNumber">Phone Number</label>
                            </div>
                        </div>
                        <div className="col py-1">
                            <div className="form-floating">
                                <input type="email" className="form-control" id="Email" placeholder="email" name="Email" onChange={event => setEmail(event.target.value)} />
                                <label for="Email">Email</label>
                            </div>
                        </div>
                        <div className="col py-1">
                            <div className="form-floating">
                                <select className="form-select" id="CourseSelector" data-bs-theme="dark" aria-label="Course Selection Menu" name="CourseSelector" onChange={event => setCourse(event.target.value)}>
                                    <option selected>Select Course</option>
                                    {
                                        courseList.map(course =>
                                            <option value={course.courseId}>{course.courseName}</option>
                                        )
                                    }
                                </select>
                                <label for="CourseSelector">Course Interested in</label>
                            </div>
                        </div>
                        <div class="col text-center pt-3 pb-3 pb-md-0">
                            <button type="submit" className="btn fs-16 fs-md-24 button-text btn-secondary" onClick={handleFormSubmit}>
                                {"Enroll".toUpperCase()}
                            </button>
                        </div>
                    </form>
                </div>
                {toastDisplay && <Toast message={toastMessage} valid={toastValid} />}
            </div>
        </>
    );
}