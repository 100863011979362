import { SectionHeadingCream } from "./SectionHeading";
import { ButtonOrange } from "./AssetButton";
import { Link } from "react-router-dom";

import { useEffect, useState } from "react"
import axios from "axios";

export function Courses() {
    const [courseList, setCourseList] = useState([]);
    useEffect(() => {
        axios
            .get("https://admin.insideit.in/api/course/read.php")
            .then((res) => setCourseList(res.data))
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }, [])
    return (
        <>
            <div className="container-fluid bg--color-grey px-0">
                <SectionHeadingCream headingName={"Courses offered"} />
                <div className="container-fluid py-3 py-md-5">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 justify-content-center">
                        <CourseGrid courseList={courseList} />
                    </div>
                </div>
            </div>
        </>
    );
}

function CourseGrid({ courseList }) {
    const courseCards = [];
    courseList.forEach((course) => {
        courseCards.push(
            <div className="row g-3 justify-content-center">
                <div className="col col-sm-8 col-md-10">
                    <div className="container-fluid d-flex flex-column bg--color-cream h-100 rounded-5">
                        <CourseLogo courseLogo={course.courseLogo} />
                        <CourseTitle courseTitle={course.courseTitle} />
                        <CourseDescriptionList courseDescription={course.courseDescription} />
                        <div className="text-center mt-auto">
                            <Link to={"/syllabus/" + course.courseId}>
                                <ButtonOrange buttonName={"SYLLABUS"} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <>
            {courseCards}
        </>
    );

}


function CourseLogo({ courseLogo }) {

    console.log(courseLogo);
    let image = require('../courselogo/' + courseLogo);
    return (
        <>
            <div className="container-sm text-center pt-4">
                <img className="img-fluid" style={{ width: "10rem" }} src={image} alt="Course Logo" />
            </div>
        </>
    );
}

function CourseTitle({ courseTitle }) {
    return (
        <>
            <div className="container-sm text-center montserrat-heading fs-20 fs-md-24 pt-4 text-secondary">
                {courseTitle}
            </div>
        </>
    );
}


function CourseDescriptionList({ courseDescription }) {

    const listItems = courseDescription.map((element) =>
        <li className="list-group-item bg--color-cream lh-1 py-md-2 px-0">{element}</li>
    )
    return (
        <>
            <div className="container-sm pt-3 pb-3 generic-text-lato fs-16 fs-md-20 text-secondary text-center px-0">
                <ul className="list-group list-group-flush">
                    {listItems}
                </ul>
            </div>
        </>
    );

}

// function FeeDetails({ fee }) {

//     let scratchFee;

//     if (fee.length > 1) {
//         scratchFee =
//             <>
//                 <span className="text-decoration-line-through pe-2 text-secondary">&#8377;{fee[0]}</span>
//                 <span>
//                     &#8377;{fee[1]}
//                 </span>
//             </>
//     } else {

//         scratchFee = <span>
//             &#8377;{fee[0]}
//         </span>
//     }

//     return (
//         <>
//             <div className="container-sm generic-text-lato fs-16 fs-md-20">
//                 <span className="pe-2 text-secondary">Fee:</span>
//                 {scratchFee}
//             </div>
//         </>
//     );
// }
