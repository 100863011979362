import { ButtonCream } from "./AssetButton";

export function HeroSection() {

    return (
        <>
            <div className="container-fluid bg--color-grey text-center py-5" id="top">
                <div className="fs-32 fs-md-40 tagline-basement pt-5 px-3">
                    <span className="text-orange">I</span><span className="text-primary">NSPIRE,</span>
                    <span className="text-orange"> E</span><span className="text-primary">DUCATE &#x26;</span>
                    <span className="text-orange"> E</span><span className="text-primary">MPOWER</span>
                </div>

                <div className="container bg--color-grey text-center text-primary fs-16 fs-md-20 px-4 py-3 px-md-5">
                    With Evolving Technologies, Insurgence in the IT Industry all over the world and Rising Competition, choosing the right training that gives you a competitive advantage over the others is of utmost importance. Inside IT provides you with expert and comprehensive software training to streamline your true potential. With the pioneering platform as ours, prepare yourself for the next step in your career. We are here to Empower Future Tech Leaders
                </div>
                <div className="pb-3">
                    <a href="#form">
                        <ButtonCream buttonName={"ENROLL NOW"} />
                    </a>
                </div>
            </div>
        </>
    );
}