import circle from "../images/no-language-barrier.png"
import { SectionHeadingBlack } from "./SectionHeading";
import useWindowDimensions from "../utils/window.js";

const values = [
    {
        id: "Language",
        title: "No Language Barrier",
        image: "no-language-barrier.png",
        description: "Our tutors are fluent in different languages including Marathi, Hindi and English, that makes learning and interaction comfortable for you."
    },
    {
        id: "Soft-Skills",
        title: "Soft Skills",
        image: "soft-skills.png",
        description: "Along with technical teaching we groom you in communication skills, presentation skills, etc. We dedicate 2 days weekly sessions on softskills."
    },
    {
        id: "Placements",
        title: "Placements",
        image: "placements.png",
        description: "We prepare you for placements and help you through all the steps from application to completion. We assure 100% placement guarantee."
    },
    {
        id: "Industry-Ready",
        title: "Industry Ready",
        image: "industry-ready.png",
        description: "Our aim is to give you the feel of industry like environment and prepare you as per industry requirements."
    },
    {
        id: "InterView-Prep",
        title: "InterView Prep",
        image: "interview-prep.png",
        description: "Aptitude, Technical interview, Coding interview, HR interview, we have recipes to crack these interviews along with company specific interviews."
    },
    {
        id: "Counselling",
        title: "Counselling",
        image: "counselling.png",
        description: "Different career paths, a lot of options can be confusing, we guide you through all the options and help you choose better."
    },
    {
        id: "Networking",
        title: "Networking",
        image: "networking.png",
        description: "Our experienced tutors have good network in the industry. They help you connect with a lot of inspiring people and companies in the world of tech."
    },
    {
        id: "Resume-Building",
        title: "Resume Building",
        image: "resume-building.png",
        description: "Your resume is the first impression of you, we help you build a perfect resume for your dream job."
    },
    {
        id: "Live-Project-Sessions",
        title: "Live Project Sessions",
        image: "live-project-sessions.png",
        description: "We provide training on live projects that help you understand the concepts and how are the concepts implemented in the corporate world."
    },
    {
        id: "Hands-on-Training",
        title: "Hands-on Training",
        image: "hands-on-training.png",
        description: "We make sure you are trained and are comfortable with the softwares that are important in the job you seek."
    },
    {
        id: "Live-Customer-Interaction",
        title: "Live Customer Interaction",
        image: "live-customer-interaction.png",
        description: "We have interactive sessions with the industry experts. You are introduced to many inspiring tech leads."
    }
];

export function ValuePropositions() {

    // eslint-disable-next-line
    const { _height, width } = useWindowDimensions();

    let valueRender = [];
    if (width <= 770) {
        valueRender = <ValueAccordion values={values} />
    } else {
        valueRender = <ValueGrid values={values} />
    }

    return (
        <>
            <div className="container-fluid pb-4 px-0 text-center">
                <SectionHeadingBlack headingName={"Why INSIDE IT?"} />
                <div className="py-4 py-md-5">
                    {valueRender}
                </div>
            </div>
        </>
    )
}

function ValueGrid({ values }) {

    // eslint-disable-next-line
    const { _height, width } = useWindowDimensions();
    let render;

    const valueCards = [];
    values.forEach((value) => {
        valueCards.push(
            <div className="col col-md-4 col-lg-3">
                <ValueCard
                    title={value.title.toUpperCase()}
                    description={value.description}
                    imgsrc={value.image}
                />
            </div>);
    });

    if (width <= 992) {
        render = (
            <div className="container align-items-stretch">
                <div className="row justify-content-center gy-2 gx-4">
                    {valueCards}
                </div>
            </div>
        );

    } else {
        render = (
            <div className="container align-items-stretch">
                <div className="row justify-content-center">
                    {valueCards[0]}
                    {valueCards[1]}
                    {valueCards[2]}
                    {valueCards[3]}
                </div>
                <div className="row justify-content-center">
                    {valueCards[4]}
                    {valueCards[5]}
                    {valueCards[6]}
                </div>
                <div className="row justify-content-center">
                    {valueCards[7]}
                    {valueCards[8]}
                    {valueCards[9]}
                    {valueCards[10]}
                </div>
            </div>
        );
    }

    return (
        render
    )
}

export function ValueCard({ imgsrc, imgWidth, title, description }) {
    let image = require('../images/' + imgsrc);
    return (
        <>
            <div className="card text-center border-0 bg--color-cream">
                <div className="card-body px-0 px-xl-3">
                    <img className="card-img-top" src={image ? image : circle} style={{ width: imgWidth ? imgWidth : '96px' }} alt=""></img>
                    <h5 className="card-title montserrat-heading fs-16 fs-md-20 pt-2">{title}</h5>
                    <p className="card-text generic-text-lato fs-12 fs-md-16">{description}</p>
                </div>
            </div>
        </>
    )
}

function ValueAccordion({ values }) {
    const accordionId = "valuesAccordion";

    const valueAccordionItems = [];
    values.forEach((value) => {
        valueAccordionItems.push(
            <ValueAccordionItem
                value={value}
                accordionId={"#" + accordionId}
            />
        );
    });

    return (
        <>
            <div className="container-sm accordion" id={accordionId}>
                {valueAccordionItems}
            </div>
        </>
    )
}

function ValueAccordionItem({ value, accordionId }) {
    const valueId = "#" + value.id;
    return (
        <>
            <div className="accordion-item">
                <h5 className="accordion-header">
                    <button className="accordion-button collapsed montserrat-heading fs-16 fs-md-20" type="button" data-bs-toggle="collapse" data-bs-target={valueId} aria-expanded="true" aria-controls={value.id}>
                        {value.title.toUpperCase()}
                    </button>
                </h5>
                <div id={value.id} className="accordion-collapse collapse generic-text-lato fs-12 fs-md-16" data-bs-parent={accordionId}>
                    <div className="accordion-body">
                        {value.description}
                    </div>
                </div>
            </div>
        </>
    )
}