import { SectionHeadingBlack } from "./SectionHeading";
export function ServiceVideoSection() {

    return (

        <>
            <div className="container-fluid text-center py-md-5 py-4">
                <SectionHeadingBlack headingName={"what's inside it?"} />
                <div className="container-sm generic-text-lato fs-16 fs-md-20 text-center text-secondary px-4 pt-3 pb-4 pb-md-5 px-md-5">
                    Inside IT helps you to accelarate your Software skills, Knowledge and Career growth step by step while fostering a culture of innovation and continuous growth. Our syllabus is curated on <span className="fw-bold">"Learning With Implementation."</span> hence bridging the gap between learning and real-time implementation by empowering individuals to be industry ready. Through our cutting-edge curriculum, Industry Expert trainers and Placement assistance we have <span className="fw-bold">100% placement rate.</span>
                </div>

                {/* <div className="px-2 px-md-0">
                    <div className="ratio ratio-16x9 container-sm">
                        <iframe src="https://www.youtube.com/embed/CLeZyIID9Bo?si=BdmuqKUpkJD7xIUd" title="YouTube video player" allowFullScreen></iframe>
                    </div>
                </div> */}
            </div >
        </>
    );
}