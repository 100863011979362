import "../styles/general.css"
export function Toast({ valid, message }) {

	return (
		<>
			<div className="container-sm col-10 col-md-4">
				<div className={"generic-text-lato py-2 px-4 fs fs-16 fs-md-20" + (valid ? " bg-success" : " bg-danger")} style={{
					zIndex: "1000",
					color: "white",
					textAlign: "center",
					verticalAlign: "center",
					borderRadius: "20px"
				}}>
					{message}
				</div>
			</div>
		</>
	)
}