export function Syllabus({ syllabus }) {
    if (!syllabus) { return }

    return (
        <>
            <SyllabusAccordion syllabus={syllabus} />
        </>
    );
}


function SyllabusAccordion({ syllabus }) {

    const accordionId = "syllabusAccordion";

    const syllabusAccordionItems = [];

    syllabus.forEach((syllabusValue) => {
        syllabusAccordionItems.push(
            <SyllabusAccordionItem
                syllabusValue={syllabusValue}
                accordionId={"#" + accordionId}
            />
        );
    });
    return (
        <>
            <div className="container accordion" id={accordionId}>
                {syllabusAccordionItems}
            </div>
        </>
    )
}

function SyllabusAccordionItem({ syllabusValue, accordionId }) {
    const syllabusValueId = "#" + syllabusValue.moduleId;

    return (
        <>
            <div className="accordion-item">
                <h5 className="accordion-header">
                    <button className="accordion-button collapsed montserrat-heading fs-12 fs-md-20" type="button" data-bs-toggle="collapse" data-bs-target={syllabusValueId} aria-expanded="true" aria-controls={syllabusValue.moduleId}>
                        {syllabusValue.module.toUpperCase()}
                    </button>
                </h5>
                <div id={syllabusValue.moduleId} className="accordion-collapse collapse generic-text-lato fs-12 fs-md-20" data-bs-parent={accordionId}>
                    <div className="accordion-body">
                        <SubHeadingDescriptionList syllabusValue={syllabusValue} />
                    </div>
                </div>
            </div>
        </>
    )
}

function SubHeadingDescriptionList({ syllabusValue }) {


    const courseContent = syllabusValue.courseContent;
    let descriptionList = [];

    descriptionList = courseContent.map(val => {
        let subHeading;
        if (val.subModule !== "") {
            subHeading = <div className="pt-2 montserrat-heading">
                {val.subModule}
            </div>
        }
        const description = val.description;
        return (
            <>
                {subHeading}
                <ul className="list-group list-group-flush">
                    {
                        description.map(des => {
                            return <li className="list-group-item bg--color-cream lh-1 py-md-2 px-0">{des}</li>
                        })
                    }
                </ul>
            </>
        )
    })
    return (
        descriptionList
    )

}