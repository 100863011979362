import envelopeFill from "../icons/envelope-fill.svg";
import telephoneFill from "../icons/telephone-fill.svg";
import instagramIcon from "../icons/Social-Icon-instagram.svg";
import linkdinIcon from "../icons/Social-Icon-linkdin.svg";
import youtubeIcon from "../icons/Social-Icon-youtube.svg";
import whatsappIcon from "../icons/Social-Icon-whatsapp.svg";
import logoLarge from "../images/logo-large.svg";
import logoMobile from "../images/logo-mobile.svg";
import { Link } from "react-router-dom";
import "../styles/general.css"
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import playstore from "../icons/Social-play-store.png";


// courses array 
// const courses = [
//     { courseName: "Software Testing", syllabusLink: "/syllabus/softwareTesting" },
//     { courseName: "Full Stack Java", syllabusLink: "/syllabus/fullStackJava" },
//     { courseName: "DevOps", syllabusLink: "/syllabus/devOps" },
//     { courseName: "Blockchain and Cryptocurrency", syllabusLink: "/syllabus/Blockchain" }
// ];


export function ContactSocials() {
    return (
        <>
            <div className=" container-fluid bg--color-orange px-2 py-2 d-none d-md-block" >
                <div className="d-flex align-items-center justify-content-center generic-text-lato fs-16 gap-3">
                    <div className="d-flex align-items-center">
                        <img src={envelopeFill} alt="envelope-icon" />
                        <p className="d-inline-block m-0 ms-2"><a className="text-secondary" href="mailto:contact@insideit.in">contact@insideit.in</a></p>
                    </div>

                    <div className="d-flex align-items-center">
                        <img src={telephoneFill} alt="telephone-icon" />
                        <p className="d-inline-block m-0 ms-2"><a className="text-secondary" href="tel:+91 96991 23855">+91 96991 23855</a></p>
                    </div>

                    <div className="d-flex align-items-center gap-3">
                        <a href="https://instagram.com/inside_it_pune?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer" className="lh-1"><img src={instagramIcon} alt="instagram-icon" /></a>
                        <a href="http://www.linkedin.com/in/inside-it-27066a291" target="_blank" rel="noreferrer" className="lh-1"><img src={linkdinIcon} alt="linkdin-icon" /></a>
                        <a href="https://www.youtube.com/channel/UCNjJFUxLQgkazA5AiDfD0fQ" target="_blank" rel="noreferrer" className="lh-1"><img src={youtubeIcon} alt="youtube-icon" /></a>
                        <a href="https://wa.me/message/424I6IVD7PL3N1" target="_blank" rel="noreferrer" className="lh-1"><img src={whatsappIcon} alt="whatsapp-icon" /></a>
                    </div>

                    <div className="d-flex align-items-center">
                        <a className="lh-1 text-secondary" href="https://play.google.com/store/apps/details?id=co.haward.shevl&pcampaignid=web_share" target="_blank" rel="noreferrer">
                            <img style={{width: "20px"}} src={playstore} alt="play-store-link" />
                            <p className="d-inline-block m-0 ms-2">Get the app on Play Store</p>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export function Navigation() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [windowWidth, setWindowWidth] = useState();
    const [courseList, setCourseList] = useState([{}]);
    const [open, setOpen] = useState(false);
    let collapseRef = useRef();

    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    const handleResize = () => {
        const position = window.innerWidth;
        setWindowWidth(position);
    }

    const collapseHandler = (e) => {
        if (!collapseRef.current.contains(e.target)) {
            setOpen(false)
        }
    }

    useEffect(() => {
        axios
            .get("https://admin.insideit.in/api/course/read.php")
            .then((res) => {
                let list = [];
                res.data.forEach(item => {
                    list.push({
                        courseName: item.courseTitle,
                        syllabusLink: '/syllabus/' + item.courseId,
                    })
                })
                console.log(list)
                setCourseList(list)
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        window.addEventListener('resize', handleResize, { passive: true });
        document.addEventListener('mousedown', collapseHandler);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            // window.removeEventListener('resize', handleResize);
            document.removeEventListener('mousedown', collapseHandler);
        };
    }, [windowWidth]);

    return (
        <>
            <nav className="container-fluid navbar navbar-dark navbar-expand-lg bg--color-grey px-lg-5 px-2 custom-nav" style={{
                position: "fixed",
                top: window.innerWidth <= 768 || scrollPosition > 50 ? "0px" : "40px",
                zIndex: "1000"
            }}>
                <div className="container-fluid position-relative" ref={collapseRef}>
                    <a className="navbar-brand d-flex align-content-center p-0 m-0 position-absolute top-0" href="/">
                        <picture>
                            <source media="(max-width:768px)" srcSet={logoMobile}></source>
                            <img src={logoLarge} alt="" />
                        </picture>
                    </a>
                    <button className="navbar-toggler ms-auto border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => { setOpen(!open) }}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={"collapse navbar-collapse justify-content-end" + (open ? " show" : "")} id="navbarContent">
                        <ul className="navbar-nav gap-lg-4">
                            <NavItemComponent title={"Home"} link={"/"} setOpen={setOpen} open={open} />
                            <NavItemComponent title={"About us"} link={"/aboutuspage"} setOpen={setOpen} open={open} />
                            <NavItemDropdownComponent courses={courseList} setOpen={setOpen} open={open} />
                            <NavItemComponent title={"Success Stories"} link={"/successStories"} setOpen={setOpen} open={open} />
                            <NavItemComponent title={"Contact us"} link={""} setOpen={setOpen} open={open} />
                            <NavItemComponent title={"FAQ"} link={"/faqsPage"} setOpen={setOpen} open={open} />
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

function NavItemComponent({ title, link, setOpen, open }) {

    if (title === "Contact us") {
        return (
            <li className="nav-item">
                <a className="nav-link" onClick={() => { setOpen(!open) }} href="#footer">
                    {title}
                </a>
            </li>
        )
    }

    return (
        <li className="nav-item">
            <Link className="nav-link" onClick={() => { setOpen(!open) }} to={link}>
                {title}
            </Link>
        </li>
    );
}

function NavItemDropdownComponent({ courses, setOpen, open }) {

    const dropdownList = courses.map((course) =>
        <li key={course.courseName}>
            <Link className="dropdown-item text-wrap course-link" onClick={() => { setOpen(!open) }} to={course.syllabusLink}>{course.courseName}</Link>
        </li >
    );
    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">Courses</a>
            <ul className="dropdown-menu bg--color-grey">
                {dropdownList}
            </ul>
        </li>
    )
}
