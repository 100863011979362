import { SectionHeadingBlack } from "../components/SectionHeading";
import { ValueCard } from "../components/ValuePropositions"
import OurMission from "../images/our-mission.png"
import OurVision from "../images/our-vision.png"


export function AboutusPage() {

    return (
        <>
            <div className="container text-center pb-5 pt-5 mt-3">
                <SectionHeadingBlack headingName={"About us"} />
            </div>
            <div className="container-sm generic-text-lato fs-16 fs-md-20 text-center text-secondary px-4 pt-3 pb-4 pb-md-5 px-md-5">
                <div className="fw-bold">Welcome to Inside IT.</div>
                <div className="py-2">We are committed to Learning With Implementation, hence bridging the gap between learning and real-time implementation by empowering individuals to be industry ready. We provide expert and comprehensive software training to streamline your true potential. Our cutting-edge curriculum is designed to be flexible and adaptable to the changing needs of the industry, ensuring that you are always up to date with the latest technologies.</div>

                <div className="py-2">Our instructors are a team of seasoned IT professionals who bring depth of real workplace knowledge and industry experience to the classroom, providing students with a unique learning experience. They are dedicated to helping students succeed and are always available to answer any questions or provide additional guidance.</div>

                <div className="py-2">With the evolution of technology, there has been an insurgence in the Information Technology field all around the world. Hence choosing a training that aids to your career is of the utmost importance. At Inside IT, we primarily focus on in-depth, interactive training that prepares you to encounter every business objective efficiently. We are equipped to meet the specifications of diverse eLearning solutions to set you apart in the job market</div>

                <div className="py-2 pb-5">With a goal to create opportunities for individuals we are conducting clear and well-organized action items like career guidance, mock-up interviews, resume building to prepare you for challenging opportunities and thereby achieving 100% Placement rate.</div>
            </div>
            <div className="container-sm py-3 pb-5 mb-5">
                <div className="row justify-content-center g-3 pb-5">
                    <div className="col text-center">
                        <ValueCard imgsrc={OurVision} title={"OUR VISION"} imgWidth={'100px'} description={"To Inspire, Educate and Empower Individuals by accelerating their Software skills, Knowledge and Career growth step by step while fostering a culture of innovation and continuous growth."} />
                    </div>
                    <div className="col text-center">
                        <ValueCard imgsrc={OurMission} title={"OUR MISSION"} imgWidth={'100px'} description={"To create opportunities for individuals and continue to be the pioneering platform by providing comprehensive software training with cutting-edge curriculum is designed to be flexible and adaptable to the changing needs of the industry."} />
                    </div>
                </div>
            </div>

        </>
    )
}