export function FAQComponent({ faqs }) {
    if (!faqs) { return }
    return (
        <>
            <FaqAccordion faqs={faqs} />
        </>
    );
}


function FaqAccordion({ faqs }) {

    const accordionId = "faqAccordion";
    const faqAccordionItems = [];

    faqs.forEach((faqValue) => {

        faqAccordionItems.push(
            <FaqAccordionItem faqValue={faqValue}
                accordionId={"#" + accordionId}
            />
        );
    });

    return (
        <>
            <div className="container accordion" id={accordionId}>
                {faqAccordionItems}
            </div>
        </>
    )
}



function FaqAccordionItem({ faqValue, accordionId }) {
    const faqValueId = "#" + faqValue.id;

    return (
        <>
            <div className="accordion-item">
                <h5 className="accordion-header">
                    <button className="accordion-button collapsed montserrat-heading fs-12 fs-md-20" type="button" data-bs-toggle="collapse" data-bs-target={faqValueId} aria-expanded="true" aria-controls={faqValue.id}>
                        {faqValue.question}
                    </button>
                </h5>
                <div id={faqValue.id} className="accordion-collapse collapse generic-text-lato fs-12 fs-md-20" data-bs-parent={accordionId}>
                    <div className="accordion-body">
                        {faqValue.answer}
                    </div>
                </div>
            </div>
        </>
    )

}

