import wipro from "../companylogos/wipro.svg";
import tcs from "../companylogos/tcs.svg";
import vodafone from "../companylogos/vodafone.svg";
import swiggy from "../companylogos/swiggy.svg";
import amdocs from "../companylogos/amdocs.png";
import capgemini from "../companylogos/capgemini.svg";
import foodpanda from "../companylogos/foodpanda.svg"
import ksolve from "../companylogos/ksolve.png";
import qualityKiosk from "../companylogos/qualityKiosk.svg";
import infosys from "../companylogos/infosys.png";
import techMahindra from "../companylogos/techMahindra.png";
import { SectionHeadingBlack } from "./SectionHeading";

export function CompanyLogoSection() {

    return (
        <>
            <div className="container-fluid bg--color-green company-logo-container">
                <div className="pb-4">
                    <SectionHeadingBlack headingName={"Our Students are Placed At"} />
                </div>
                <div className="row m-0 p-0 justify-content-center align-items-center">
                    <div className="col-3 p-0 m-0 container d-flex justify-content-center">
                        <img src={wipro} alt="" />
                    </div>
                    <div className="col-3 p-0 m-0 container d-flex justify-content-center">
                        <img src={tcs} alt="" />
                    </div>
                    <div className="col-3 p-0 m-0 container d-flex justify-content-center">
                        <img src={vodafone} alt="" />
                    </div>
                    <div className="col-3 p-0 m-0 container d-flex justify-content-center">
                        <img src={swiggy} alt="" />
                    </div>
                </div>
                {/* row 2 */}
                <div className="row m-0 p-0 justify-content-center align-items-center">
                    <div className="col-3 p-0 m-0 container d-flex justify-content-center">
                        <img src={capgemini} alt="" />
                    </div>
                    <div className="col-3 p-0 m-0 container d-flex justify-content-center">
                        <img src={foodpanda} alt="" />
                    </div>
                    <div className="col-3 p-0 m-0 container d-flex justify-content-center">
                        <img src={ksolve} alt="" />
                    </div>
                    <div className="col-3 p-0 m-0 container d-flex justify-content-center">
                        <img src={qualityKiosk} alt="" />
                    </div>
                </div>
                {/* row 3 */}
                <div className="row m-0 p-0 justify-content-center align-items-center pb-4">
                    <div className="col-3 p-0 m-0 container d-flex justify-content-center">
                        <img src={infosys} alt="" />
                    </div>
                    <div className="col-3 p-0 m-0 container d-flex justify-content-center">
                        <img src={amdocs} alt="" />
                    </div>
                    <div className="col-3 p-0 m-0  container d-flex justify-content-center">
                        <img src={techMahindra} alt="" />
                    </div>
                    <div className="col-3 p-0 m-0 text-center container d-flex justify-content-center oswald-heading text-secondary fs-20 fs-md-32 fs-lg-40">
                        and many more...
                    </div>
                </div>
            </div>
        </>
    );
}
