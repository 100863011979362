export function SectionHeadingBlack({ headingName }) {

    return (
        <SectionHeading name={headingName} headingStyle={"text-secondary"} />
        // <div className="container-fluid text-center fs-24 fs-md-40 text-secondary oswald-heading py-md-4 py-2">
        // {headingName.toUpperCase()}
        // </div>
    );
}

export function SectionHeadingCream({ headingName }) {

    return (
        <SectionHeading name={headingName} headingStyle={"text-primary"} />
        // <div className="container-fluid text-center fs-24 fs-md-40 text-primary oswald-heading py-md-4 py-2">
        //     {headingName.toUpperCase()}
        // </div>
    );
}

export function SectionHeading({ name, headingStyle }) {
    return (
        <div className={"container-fluid text-center fs-24 fs-md-40 oswald-heading pt-3 pt-md-5 " + headingStyle}>
            {name.toUpperCase()}
        </div >
    )
}