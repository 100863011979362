import { useEffect, useState } from "react";
import axios from "axios";
import { FAQComponent } from "../components/FAQComponent";

export function FaqsPage() {


    const [faqs, setfaqs] = useState([]);
    useEffect(() => {
        axios
            .get("https://admin.insideit.in/api/faq/read.php")
            .then((res) => {
                setfaqs(res.data)
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }, [])


    return (
        <>
            <div className="container-fluid px-0 pt-2 pb-4 pb-md-5 mt-3">
                <div className="container pt-2 pt-md-5 ps-4 ps-md-2">
                    <div className="fs-24 fs-md-40 oswald-heading text-secondary pb-3">
                        Frequently Asked Questions
                    </div>
                </div>
                <FAQComponent faqs={faqs} />
            </div>
        </>
    )
}