import logoLargeDark from "../images/logoLargeDark.svg";
import logoMobileDark from "../images/logoMobileDark.svg";
import { ButtonCreamDark } from "./AssetButton";
import envelopeFill from "../icons/envelope-fill.svg";
import telephoneFill from "../icons/telephone-fill.svg";
import instagramIcon from "../icons/Social-Icon-instagram.svg";
import linkdinIcon from "../icons/Social-Icon-linkdin.svg";
import youtubeIcon from "../icons/Social-Icon-youtube.svg";
import whatsappIcon from "../icons/Social-Icon-whatsapp.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import PlayBadge from "../icons/google-play-badge.png";

export function Footer() {
    const [courseList, setCourseList] = useState([{}]);

    useEffect(() => {
        axios
            .get("https://admin.insideit.in/api/course/read.php")
            .then((res) => {
                let list = [];
                res.data.forEach(item => {
                    list.push({
                        courseName: item.courseTitle,
                        syllabusLink: '/syllabus/' + item.courseId,
                    })
                })
                console.log(list)
                setCourseList(list)
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }, [])

    return (
        <>
            <div className="container-fluid row bg--color-orange pt-5 mx-0 footerCustom text-nowrap" id="footer">
                <div className="container pt-3 pb-3 col-6 col-md-3 text-center">
                    <a href="/">
                        <picture>
                            <source media="(max-width:768px)" srcSet={logoMobileDark}></source>
                            <img src={logoLargeDark} alt="" />
                        </picture>
                    </a>

                    <div className="container px-0">
                        <Link to="/#footer">
                            <ButtonCreamDark buttonName={"ENROLL NOW"} />
                        </Link>
                    </div>
                    <div className="container px-0">
                        <a href="https://play.google.com/store/apps/details?id=co.haward.shevl&pcampaignid=web_share" target="_blank" rel="noreferrer">
                            <img className="img-fluid play-store-badge" src={PlayBadge} alt="play-store" />
                        </a>
                    </div>
                </div>

                <div className="container-sm col-6 col-md-3 pt-3 pb-3 text-center">
                    <div className="container">
                        <span className="montserrat-heading fs-12 fs-md-24">Contact us</span>
                        <div className="d-flex align-items-center justify-content-center fs-12 fs-md-20">
                            <img src={envelopeFill} alt="envelope-icon" />
                            <p className="d-inline-block m-0 ms-2"><a className="text-secondary" href="mailto:contact@insideit.in">contact@insideit.in</a></p>
                        </div>
                        <div className="d-flex align-items-center justify-content-center fs-12 fs-md-20">
                            <img src={telephoneFill} alt="telephone-icon" />
                            <p className="d-inline-block m-0 ms-2"><a className="text-secondary" href="tel: +91 96991 23855">+91 96991 23855</a></p>
                        </div>
                    </div>
                </div>


                <div className="container-sm col-6 col-md-3 pt-3 pb-3 text-center">
                    <div className="container">
                        <span className="montserrat-heading fs-12 fs-md-24">Courses</span>
                        <div className="fs-12 fs-md-20 d-flex flex-column">
                            <FooterCourseList courses={courseList} />
                        </div>
                    </div>
                </div>

                <div className="container-sm col-6 col-md-3 pt-3 pb-3 text-center">
                    <div className="container">
                        <span className="montserrat-heading fs-12 fs-md-24">Overview</span>
                        <div className="fs-12 fs-md-20 d-flex flex-column">
                            <div className="d-inline-block">
                                <a className="text-decoration-none text-secondary" href="#top">
                                    Home
                                </a>
                            </div>
                            <div className="d-inline-block">
                                <Link className="text-decoration-none text-secondary" to="/aboutuspage">
                                    About us
                                </Link>
                            </div>
                            <div className="d-inline-block">
                                <Link className="text-decoration-none text-secondary" to="/successStories">
                                    Success Stories
                                </Link>
                            </div>
                            <div className="d-inline-block">
                                <Link className="text-decoration-none text-secondary" to="/faqsPage">
                                    FAQ
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-sm text-center">
                    <span className="fs-12 fs-md-24 text-secondary montserrat-heading">Follow us on</span>
                    <div className="d-flex align-items-center justify-content-center gap-3 pb-4 pt-3">
                        <a href="https://instagram.com/inside_it_pune?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer" className="lh-1"><img src={instagramIcon} alt="instagram-icon" /></a>
                        <a href="http://www.linkedin.com/in/inside-it-27066a291" target="_blank" rel="noreferrer" className="lh-1"><img src={linkdinIcon} alt="linkdin-icon" /></a>
                        <a href="https://www.youtube.com/channel/UCNjJFUxLQgkazA5AiDfD0fQ" target="_blank" rel="noreferrer" className="lh-1"><img src={youtubeIcon} alt="youtube-icon" /></a>
                        <a href="https://wa.me/message/424I6IVD7PL3N1" target="_blank" rel="noreferrer" className="lh-1"><img src={whatsappIcon} alt="whatsapp-icon" /></a>
                    </div>
                </div>
            </div >
        </>
    );

}

function FooterCourseList({ courses }) {
    console.log(courses);
    const list = courses.map((course) =>
        <div className="d-inline-block">
            <Link className="text-secondary text-wrap text-decoration-none" to={course.syllabusLink}>
                {course.courseName}
            </Link>
        </div>
    );
    return (<>
        {list}
    </>);
}