import { useParams } from "react-router-dom"
import { Syllabus } from "../components/Syllabus";

import { useEffect, useState } from "react"
import axios from "axios";


export function SyllabusPage() {
    const { id } = useParams();

    const [detailSyllabus, setdetailSyllabus] = useState([]);
    useEffect(() => {
        axios
            .get("https://admin.insideit.in/api/syllabus/read.php?id=" + id)
            .then((res) => {
                setdetailSyllabus(res.data)
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }, [id])

    const syllabus = detailSyllabus.syllabus;
    let courseTitle = detailSyllabus.courseTitle;

    return (

        <>
            <div className="container-fluid px-0 pt-2 pb-4 pb-md-5">
                <div className="container pt-2 pt-md-5 ps-4 ps-md-2">
                    <div className="montserrat-heading fs-12 fs-md-16">SYLLABUS</div>
                    <div className="fs-24 fs-md-40 oswald-heading text-secondary pb-3">
                        {courseTitle}
                    </div>
                </div>
                <Syllabus syllabus={syllabus} />
            </div>
        </>
    )
}